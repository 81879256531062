<script setup lang="ts">
import { today, getLocalTimeZone } from '@internationalized/date'
import { RequestReadSearchLocationInfos, RequestReadSearchLocations } from '@yescapa-dev/ysc-api-js/modern'
import type { APISearchLocationItem } from '@yescapa-dev/ysc-api-js/modern'
import { LOCALE_CODES } from '@yescapa-dev/ysc-components/i18n'
import type { SearchFilters } from '~~/types/api/search'

const {
  DE,
  EN_GB,
  IT,
  PT,
  FR,
  ES,
  NL,
} = LOCALE_CODES

const { $i18n: { localeProperties } } = useNuxtApp()

const results = ref<APISearchLocationItem[]>([])
const pendingQuery = ref<Partial<Pick<SearchFilters, 'where' | 'latitude' | 'longitude' | 'date_from' | 'date_to' | 'radius'>>>({
  where: undefined,
  latitude: undefined,
  longitude: undefined,
  date_from: undefined,
  date_to: undefined,
  radius: undefined,
})

const destination = computed(() => pendingQuery.value.where ?? '')

const { api } = useRentalAPI()

const onDestinationQueryChange = async (val: string | null) => {
  if (!val || val.trim().length < 3) {
    return
  }

  val = val.trim()
  try {
    results.value = await api.send(new RequestReadSearchLocations({ query: {
      search: val,
      language: localeProperties.value.yscCode,
    } }),
    )
  }
  catch (e) {
    console.error(e)
  }
}

const onDestinationChange = async (args: { locationId: string, label: string, countryCode: string } | null) => {
  if (!args) return

  const { locationId, label } = args
  let latitude, longitude, radius
  try {
    const data = await api.send(new RequestReadSearchLocationInfos({ query: { location_id: locationId } }))
    latitude = data.displayPosition.latitude
    longitude = data.displayPosition.longitude
    radius = data.radius
  }
  catch (e) {
    console.error(e)
    return
  }

  pendingQuery.value.where = label
  pendingQuery.value.latitude = latitude
  pendingQuery.value.longitude = longitude
  pendingQuery.value.radius = radius
}

const { coords, error, resume } = useGeolocation({ enableHighAccuracy: false, immediate: false })

watch(coords, () => {
  // Test on Number.isFinite because the default value of useGeolocation.coords are Infinity
  if (Number.isFinite(coords.value.latitude) && Number.isFinite(coords.value.longitude)) {
    pendingQuery.value.latitude = coords.value.latitude
    pendingQuery.value.longitude = coords.value.longitude
  }
})

watch(error, () => {
  if (error.value) {
    pendingQuery.value.where = null
    pendingQuery.value.latitude = null
    pendingQuery.value.longitude = null
    return
  }
})

const { t } = useI18n()
const onDestinationAroundMe = () => {
  resume()
  pendingQuery.value.where = t('around_me')
  pendingQuery.value.radius = 5000
}

const onDatesChange = (evt: { start?: string | null, end?: string | null } | null) => {
  const { start, end } = evt || {}
  pendingQuery.value.date_from = start ?? null
  pendingQuery.value.date_to = end ?? null
}

const goToQuery = async () => {
  const { where, latitude, longitude, radius, date_from, date_to } = pendingQuery.value

  const domains: Record<string, string> = {
    [DE]: 'https://yescapa.de/s/',
    [EN_GB]: 'https://yescapa.co.uk/s/',
    [IT]: 'https://yescapa.it/s/',
    [PT]: 'https://yescapa.pt/s/',
    [FR]: 'https://yescapa.fr/s/',
    [ES]: 'https://yescapa.es/s/',
    [NL]: 'https://yescapa.nl/s/',
  }

  const url = new URL(domains[localeProperties.value.yscCode] || 'https://yescapa.com/s/')

  url.searchParams.set('page', '1')

  if (date_from && date_to) {
    url.searchParams.set('date_from', date_from)
    url.searchParams.set('date_to', date_to)
  }
  if (where) {
    url.searchParams.set('where', where)
  }
  if (latitude) {
    url.searchParams.set('latitude', latitude.toString())
  }
  if (longitude) {
    url.searchParams.set('longitude', longitude.toString())
  }
  if (radius) {
    url.searchParams.set('radius', radius.toString())
  }

  await navigateTo(url.toString(), { external: true })
}
</script>

<template>
  <div class="mx-auto max-w-screen-lg md:px-4 py-10">
    <div class="bg-peacock-700 px-4 md:px-6 py-4 md:py-6 space-y-4 md:space-y-6 md:rounded-md">
      <span class="block text-white text-2xl font-semibold">{{ $t('rent_ideal_vehicle') }}</span>
      <YscSearchWidget
        :results="results"
        :destination="destination"
        :min-date="today(getLocalTimeZone()).toString()"
        @destination-query-change="onDestinationQueryChange"
        @destination-change="onDestinationChange"
        @around-me="onDestinationAroundMe"
        @dates-change="onDatesChange"
        @submit="goToQuery"
      >
        <template #destination-label>
          {{ $t('destination.label') }}
        </template>
      </YscSearchWidget>
    </div>
  </div>
</template>
