import validate from "/home/node/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45header_45version_45global from "/home/node/app/middleware/00.header-version.global.ts";
import _05_45remove_45trailing_45slash_45global from "/home/node/app/middleware/05.remove-trailing-slash.global.ts";
import _10_45send_45events_45global from "/home/node/app/middleware/10.send-events.global.ts";
import _20_45abort_45on_45special_45backend_45redirects_45global from "/home/node/app/middleware/20.abortOnSpecialBackendRedirects.global.ts";
import manifest_45route_45rule from "/home/node/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45header_45version_45global,
  _05_45remove_45trailing_45slash_45global,
  _10_45send_45events_45global,
  _20_45abort_45on_45special_45backend_45redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "page-one-permanent-redirect": () => import("/home/node/app/middleware/page-one-permanent-redirect.ts")
}